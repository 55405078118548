// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-container {
  width: 755px;
  margin: auto;
  padding: 20px 8px;
}

.response-option {
  display: flex;
  margin: auto;
}

.content-box {
  width: 240px;
}

.content-title {
  margin-top: 20px;
}

.content-subtitle {
  width: 735px;
  padding: 10px 0;
  margin: 10px 0;
}

.content-hr {
  height: 1px;
  margin: 20px 0;
}

.content-box-title {
  padding: 10px 0;
}

.content-select {
  padding: 18px 0;
}

.reply-method {
  padding: 50px 0;
}

@media screen and (max-width: 425px) {
  .content-box {
    width: 130px;
  }
  .content-subtitle {
    width: 400px;
  }
  .reply-method {
    width: 203px;
  }
  .content-hr {
    width: 400px;
  }
  .content-select {
    width: 400px;
  }
}
@media screen and (max-width: 375px) {
  .content-box {
    width: 115px;
  }
  .content-subtitle {
    width: 360px;
  }
  .reply-method {
    width: 179px;
  }
  .content-hr {
    width: 360px;
  }
  .content-select {
    width: 360px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/payment-setting-skeleton/payment-setting-skeleton.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,iBAAA;AACF;;AACA;EACE,aAAA;EAEA,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AACA;EACE,gBAAA;AAEF;;AAAA;EACE,YAAA;EACA,eAAA;EACA,cAAA;AAGF;;AADA;EACE,WAAA;EACA,cAAA;AAIF;;AAFA;EACE,eAAA;AAKF;;AAHA;EACE,eAAA;AAMF;;AAJA;EACE,eAAA;AAOF;;AALA;EACE;IACE,YAAA;EAQF;EANA;IACE,YAAA;EAQF;EANA;IACE,YAAA;EAQF;EANA;IACE,YAAA;EAQF;EANA;IACE,YAAA;EAQF;AACF;AANA;EACE;IACE,YAAA;EAQF;EANA;IACE,YAAA;EAQF;EANA;IACE,YAAA;EAQF;EANA;IACE,YAAA;EAQF;EANA;IACE,YAAA;EAQF;AACF","sourcesContent":[".content-container{\n  width: 755px;\n  margin:auto;\n  padding:20px 8px;\n}\n.response-option{\n  display: flex;\n  //width:755px;\n  margin:auto;\n  //padding:0 8px;\n}\n.content-box{\n  width: 240px;\n}\n.content-title{\n  margin-top: 20px;\n}\n.content-subtitle{\n  width:735px;\n  padding:10px 0;\n  margin:10px 0;\n}\n.content-hr{\n  height:1px;\n  margin:20px 0;\n}\n.content-box-title{\n  padding:10px 0 ;\n}\n.content-select{\n  padding:18px 0;\n}\n.reply-method{\n  padding:50px 0;\n}\n@media screen and (max-width: 425px){\n  .content-box{\n    width: 130px;\n  }\n  .content-subtitle{\n    width:400px;\n  }\n  .reply-method{\n    width: 203px\n  }\n  .content-hr{\n    width:400px;\n  }\n  .content-select{\n    width: 400px;\n  }\n}\n@media screen and (max-width: 375px){\n  .content-box{\n    width: 115px;\n  }\n  .content-subtitle{\n    width:360px;\n  }\n  .reply-method{\n    width: 179px\n  }\n  .content-hr{\n    width:360px;\n  }\n  .content-select{\n    width: 360px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
