import {Injectable} from '@angular/core';
import {ApiService, TokenService} from '../../../core/services';
import {map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs/operators';

@Injectable({providedIn: 'root'})


export class RegularpostService {

  constructor(private apiService: ApiService) {
  }


  createRegularPost(params: any) {
    const url = '/posts'
    return this.apiCall(url, params);
  }

  updateRegularPost(params, id) {
    const url = `/posts/${id}`
    return this.apiService.put(url, params).toPromise()
  }

  saveAsTemplate(params) {
    const url = '/templates/post'
    return this.apiCall(url, params)
  }


  getRegularPostList(page:number,tab: any, start_date?: string, end_date?: string, searchString?) {
    let url;
    if (start_date !== '' && end_date !== '') {
      url = `/posts?page=${page}&tab=${tab}&limit=10&search=${searchString}&start_date=${start_date}&end_date=${end_date}`
    } else {
      url = `/posts?page=${page}&tab=${tab}&limit=10&search=${searchString}`
    }
    return this.apiService.get(url).toPromise()
  }


  getRegularPostById(id: number) {
    let url = `/posts/${id}`
    return this.apiService.get(url).toPromise()
  }

  getRegularPostTemplateById(id:number) {
    let url = `/templates/${id}`
    return this.apiService.get(url).toPromise()
  }

  deleteRegularPost(id:number) {
    let url = `/posts/${id}`
    return this.apiService.del(url).toPromise()
  }

  private async apiCall(url, params) {
    return this.apiService.post(url, params).toPromise();
  }


}
