// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-order-list {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.no-order-list .no-order-list-img {
  margin-bottom: 24px;
}
.no-order-list .bold-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  letter-spacing: -0.2px;
  margin: 0;
}
.no-order-list .normal-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/no-list/no-list.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACF;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,sBAAA;EACA,SAAA;AADJ;AAIE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;AAFJ","sourcesContent":[".no-order-list{\n  margin-top: 50px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n\n  .no-order-list-img{\n    margin-bottom: 24px;\n  }\n\n  .bold-text{\n    font-size: 18px;\n    font-weight: 700;\n    line-height: 21.78px;\n    letter-spacing: -0.2px;\n    margin: 0;\n  }\n\n  .normal-text{\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 22px;\n    letter-spacing: -0.4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
