// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-div {
  background: rgb(245, 245, 245);
  border: 1px solid rgb(197, 197, 199);
  width: 100%;
  height: 100%;
  word-break: break-all;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.custom-div::-webkit-scrollbar {
  display: none;
}
.custom-div a {
  color: #d10a11;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/text-editor/text-editor.component.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,oCAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;EACA,kBAAA;EACA,wBAAA,EAAA,gBAAA;EACA,qBAAA,EAAA,YAAA;AACF;AAAE;EACE,aAAA;AAEJ;AACE;EACE,cAAA;EACA,iBAAA;AACJ","sourcesContent":[".custom-div {\n  background: rgba(245, 245, 245, 1);\n  border: 1px solid rgba(197, 197, 199, 1);\n  width: 100%;\n  height: 100%;\n  word-break: break-all;\n  overflow-y: scroll;\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n  &::-webkit-scrollbar {\n    display: none;\n  }\n\n  a {\n    color: #d10a11;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
