import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {JatakService} from "../../../features/jatak/services";
import {ErrorHandlerService} from "../../../core/services";
import {take} from "rxjs/operators";
import {RegularpostService} from "../../../features/regular-posts/services/regularpost.service";
import {Store} from "@ngxs/store";
import {FormAction} from "../../../store/actions/form.action";
import ClearFormValue = FormAction.ClearFormValue;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @ViewChild('searchBox') searchBox: ElementRef;
  @Input() showTemplateFolder: boolean = true
  @Input() isTemplateFolder: boolean = false
  @Input() isJatakPlus: boolean = false
  @Input() searchFolderTemplate: boolean = false
  @Output() formData = new EventEmitter<any>()
  isInputfieldClicked = false;
  isSearchClicked = false;
  searchString: string;
  templatePage: number = 1;
  resultTemplates: any = []
  loading = true;
  resultTemplatesPage: number;
  resultFolder: any[] = []
  showOfferOption = false;
  isJatakClicked: boolean = true
  isRegularPostCliked: boolean = true
  isCompetitionClicked: boolean = true
  isWheelsClicked : boolean = true
  isCardClicked : boolean = true
  types: any = ['jatak', 'post', 'competition','spinning_wheel','scratch_card']

  @Output() resultFolderEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() type : EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private _route: Router,
    private _service: JatakService,
    private _postService: RegularpostService,
    private _errorHandler: ErrorHandlerService,
    private _store : Store
  ) {
  }

  ngOnInit() {
  }

  onInputClicked() {
    this.isInputfieldClicked = true
  }

  search(searchInput: string) {
    this.types = ['jatak', 'post', 'competition']
    if (searchInput === '') {
      return;
    }
    this.isSearchClicked = true;
    this.isInputfieldClicked = false
    this.searchString = searchInput;
    this.resultTemplates = []
    if (searchInput !== '' && searchInput.trim().length !== 0) {
      if (this.isTemplateFolder) {
        this.getSearchFolderList()
      } else {
        this.getSearchTemplates()
      }
    }
  }

  closeSearch() {
    this.searchBox.nativeElement.value = null;
    this.searchString = ''
    // this.showResults = false
    this.isSearchClicked = false
    this.resultTemplates = []
    this.templatePage = 1;
    // if (this.isTemplateFolder) {
    //   this.getFolders()
    // }
  }

  getFolders() {
    this._service.getFolderList(this.templatePage).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.resultFolder = res['data']['folders']
        this.resultFolderEmitter.emit(this.resultFolder)
      }
    })
  }

  getSearchTemplates() {
    this.loading = true
    this._service.searchTemplatesList(this.searchString, this.types).subscribe({
      next: (res) => {
        this.resultTemplates = this.resultTemplates.concat(res['data']['data'])
        this.resultTemplatesPage = res['data']['meta']['last_page']
        this.loading = false
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }

  private getSearchFolderList() {
    this._service.searchFolderList(this.searchString, this.templatePage).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.resultTemplates = this.resultTemplates.concat(res['templates']['data'])
        this.resultTemplatesPage = res['templates']['meta']['last_page']
        this.resultFolder = res['folders']['data']
        if (this.resultFolder.length) {
          this.resultFolderEmitter.emit(this.resultFolder)
        }
        this.loading = false
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }

  hideSearchResult() {
    // this.closeSearch()
  }

  navigatetoTemplate() {
    this._route.navigate(['/tabs/jatak/templates'], {replaceUrl: false}).then()
  }

  onTemplateSelect(list: any) {
    this._store.dispatch(new FormAction.ClearFormValue())
    this.isSearchClicked = false

    if (this.isJatakPlus) {
      if (list.type === 'jatak') {
        this._service.getTemplateById(list.id).subscribe({
          next: (res) => {
            this.formData.emit(res)
          },
          error: (err) => {
            this.handleError(err)
          }
        })
      } else {
        this._postService.getRegularPostTemplateById(list.id).then((res) => {
          this.formData.emit(res['data'])
        }).catch((err) => {
          this.handleError(err)
        })
      }
    } else {
      this.type.emit(list)
    }
  }

  loadMore(event: any) {
    event.stopPropagation();
    this.templatePage++;
    this.isSearchClicked = true
    if (this.isTemplateFolder) {
      this.getSearchFolderList()
    } else {
      this.getSearchFolderList()
    }
  }

  toggleType(type: string) {
    const index = this.types.indexOf(type);
    if (index !== -1) {
      this.types.splice(index, 1)
    } else {
      this.types.push(type)
    }
    if (type === 'jatak') {
      this.isJatakClicked = !this.isJatakClicked
    } else if (type === 'competition') {
      this.isCompetitionClicked = !this.isCompetitionClicked
    }else if (type === 'spinning_wheel'){
      this.isWheelsClicked = !this.isWheelsClicked
    } else if (type === 'scratch_card') {
      this.isCardClicked = !this.isCardClicked
    } else {
      this.isRegularPostCliked = !this.isRegularPostCliked
    }
    this._service.searchTemplatesList(this.searchString, this.types).subscribe({
      next: (res) => {
        this.resultTemplates = res['data']['data']
        this.resultTemplatesPage = res['data']['meta']['last_page']
        this.loading = false
      },
      error: (err) => {
        this.handleError(err)
      }
    })
    // this.getSearchTemplates()

  }

  toggleselectType() {
    this.showOfferOption = !this.showOfferOption;
  }

  handleError(err: any) {
    this.loading = false
    this._errorHandler.handleError(err, '')
  }
}
