import {Injectable} from '@angular/core';
import {ApiService, TokenService} from '../../../core/services';
import {filter, map} from 'rxjs/operators';
import {Jatak, JatakItems, JatakList, OrderData} from "../interface";
import {Observable, throwError} from 'rxjs';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';

@Injectable({providedIn: 'root'})

export class JatakService {
  constructor(private apiService: ApiService) {
  }


  async getCalenderList(start_date?: any, end_date?: any): Promise<any> {
    let url = `/user/calendar?start_date=${start_date}&end_date=${end_date}`
    return this.apiService.get(url).toPromise()
  }

  async getCalendarJataks(start_date?: any, end_date?: any): Promise<JatakList[]> {
    const kardex = await TokenService.getUserCode();
    let url = `/jatak?kardex=${kardex}`;
    if (start_date) {
      url = url + `&startDate=${start_date}&endDate=${end_date}`;
    }
    return this.apiService.get(url).pipe(
      map(
        res => res['offers']
      )
    ).toPromise();
  }


  async getJatakListing(page: number,
                        limit: number,
                        status: number,
                        sortBy: number,
                        searchString?: string,
                        startDate?: string,
                        endDate?: string): Promise<JatakItems[]> {

    // let

    if (searchString) {
      const url = `/jatak/offers?page=${page}&limit=${limit}&status=${status}&sort_by=${sortBy}&search=${searchString}&start_date=${startDate}&end_date=${endDate}`;
      return this.apiService.get(url).pipe(
        map(res => res['data'])).toPromise();
    } else {
      const url = `/jatak/offers?page=${page}&limit=${limit}&status=${status}&sort_by=${sortBy}&start_date=${startDate}&end_date=${endDate}`;
      return this.apiService.get(url).pipe(
        map(res => res['data'])).toPromise();
    }
  }

  saveFavourite(id, favSate) {
    let isFav
    if (favSate) {
      isFav = 1
    } else {
      isFav = 0
    }
    const url = `/jatak/${id}/toggle-favourite?is_favourite=${isFav}`
    return this.apiService.post(url, {})
  }

  async getSingleFbPost(id: number): Promise<Jatak> {
    const url = `/jatak/${id}`;
    return this.apiService.get(url).pipe(
      map(res => res['data'])
    ).toPromise();
  }

  getTemplateById(id: number) {
    const url = `/templates/${id}`
    return this.apiService.get(url).pipe(
      map(res => res['data'])
    )
  }


  async getSingleJatakOffer(id: number): Promise<Jatak> {
    const url = `/jatak/${id}`;
    return this.apiService.get(url).pipe(
      map(res => res['data'])
    ).toPromise();
  }


  async saveJatak(params: Jatak): Promise<any> {
    // check if the new jatak is created from Archive
    let archiveID;
    if (params.is_archived) {
      archiveID = params.id
      delete params.id;
      delete params.is_archived;
    }
    if (params.id) {
      return this.editJatak(params.id, params);
    }
    return this.createJatak(params, archiveID);
  }

  async createJatak(params: any, archiveId: string): Promise<any> {
    const url = archiveId ? `/jatak?fromArchive=${archiveId}` : '/jatak';
    return this.apiCall(url, params);
  }

  async editJatak(id, params: any): Promise<any> {
    const url = `/jatak/${id}`;
    return this.apiService.put(url, params).pipe(
      map(res => res['offers'])).toPromise();
  }

  getOrder(id: string) {
    return this.apiService.get(`/jatak/${id}/order-list`).pipe()
  }

  async getOrderList(id: string): Promise<any> {
    const url = `/jatak/${id}/order-list`;
    return this.apiService.getJatak(url).pipe(map(
      res => {
        const data = res;
        //  const noOfCustomers = Object.keys(data.data.FACEBOOK.customers).length;
        //  let noOfOrders = 0;
        //  for (const customer_id in data.data.FACEBOOK.customers) {
        //    if (data.data.FACEBOOK.customers.hasOwnProperty(customer_id)) {
        //     const customerOrder = data.data.FACEBOOK.customers[customer_id].orders;
        //      noOfOrders += customerOrder.length;
        //      data.data.FACEBOOK.customers[customer_id].approval_remaining = false;
        //     for (let i = 0; i < customerOrder.length; i++) {
        //       if (customerOrder[i].to_approve) {
        //         data.data.FACEBOOK.customers[customer_id].approval_remaining = true;
        //         break;
        //       }
        //     }
        //    }
        // }
        return data;
      }
    )).toPromise();
  }

  // to get the list of orders for populating order list
  async getVariantOrderList(id: string): Promise<OrderData> {
    const url = `/jatak/${id}/order-list`;
    return this.apiService.getJatak(url).pipe(map(
      res => {
        const data = res;
        const noOfCustomers = Object.keys(data?.data?.FACEBOOK?.customers).length;
        let noOfOrders = 0;
        for (const customer_id in data?.data?.FACEBOOK?.customers) {
          if (data?.data?.FACEBOOK?.customers.hasOwnProperty(customer_id)) {
            const customerOrder = data?.data?.FACEBOOK?.customers[customer_id]?.orders;
            noOfOrders += customerOrder.length;
            data.data.FACEBOOK.customers[customer_id].approval_remaining = false;
            for (let i = 0; i < customerOrder.length; i++) {
              if (customerOrder[i].to_approve) {
                data.data.FACEBOOK.customers[customer_id].approval_remaining = true;
                break;
              }
            }
          }
        }
        data.noOfCustomers = noOfCustomers;
        data.noOfOrders = noOfOrders;
        return data;
      }
    )).toPromise();
  }

  //Mark as pickuped
  //reject all
  async updateOrderCollected(jatakId, customerId, status): Promise<any> {
    const url = `/jatak/${jatakId}/order-list/${customerId}/${status}`;
    return this.apiService.postJatak(url, '').toPromise();
  }

  // to approve the selected order
  //for approve pending orders
  async updateOrderList(quantity, orderId, jatakId): Promise<any> {
    const url = `/jatak/${jatakId}/order-list/${orderId}/approve?quantity=${quantity}`;
    return this.apiService.postJatak(url, ApiService.createFormData(quantity)).toPromise();
  }

  async deleteJatak(id: string): Promise<any> {
    const url = `/jatak/${id}`;
    return this.apiService.del(url).toPromise();
  }

  //reject pending orders
  async rejectOrder(jatakId, orderId): Promise<any> {
    const url = `/jatak/${jatakId}/order-list/${orderId}/reject`;
    return this.apiService.postJatak(url, '').toPromise();
  }

  getJatakReport(id): Observable<any> {
    const url = `/jatak/${id}/order-list/pdf`;
    return this.apiService.getJatak(url, {responseType: 'blob'}).pipe(
      catchError(this._handlerError()));
  }

  private async apiCall(url, params) {
    return this.apiService.post(url,params).toPromise();
  }


  async getJatakSetting() {
    const url = '/jatak/settings'
    return this.apiService.get(url).pipe(
      map(res => res['data'])
    ).toPromise()
  }


  createLikeChallenge(params: any) {
    const url = '/like-challenge'
    return this.apiCall(url, params);
  }

  updateLikeChallenge(params: any, id: number) {
    const url = `/like-challenge/${id}/update`
    return this.apiService.put(url, params).toPromise()
  }

  deleteLikeChallenge(id: number) {
    const url = `/like-challenge/${id}`
    return this.apiService.del(url).toPromise()
  }

  updateLikeChallengeJatak(params: any, jatakid: any, challengeId: number) {
    const url = `/like-challenge/${challengeId}/jatak/${jatakid}/update`
    return this.apiService.put(url, params).toPromise()
  }

  getLikeChallenge(id: number) {
    const url = `/like-challenge/${id}`
    return this.apiService.get(url).pipe(
      map(res => res['data'])
    )
  }

  saveAsTemplate(params: Object) {
    const url = '/templates/jatak'
    return this.apiCall(url, params)
  }

  getFolderList(page: number) {
    const url = '/folders'
    return this.apiService.get(url)
  }

  getTemplateList(id: number, page: number) {
    const url = `/folders/${id}?page=${page}`
    return this.apiService.get(url)
  }

  getOwnTemplates(folderType: string, page: number) {
    const url = `/folders/${folderType}?page=${page}`
    return this.apiService.get(url)
  }

  // for template search
  searchTemplatesList(searchString: string, types: []) {
    const filter = types.map((item) => {
      return `filters[]=${item}`
    }).join('&')
    const url = `/templates/search?search=${searchString}&${filter}`
    return this.apiService.get(url)
  }

  // search template from folder

  searchFolderTemplate(folder_id: number, searchString: string) {
    const url = `/folders/${folder_id}?search=${searchString}`
    return this.apiService.get(url)
  }

  // search template from own or assigned

  searchSavedTemplateList(type: string, searchString: string) {
    const url = `/folders/${type}?search=${searchString}`
    return this.apiService.get(url);
  }

  // search folder and template

  searchFolderList(searchString: string, page: number) {
    const url = `/folders/search?search=${searchString}&folder=true&page=${page}`
    return this.apiService.get(url)
  }

  private _handlerError() {
    return (error: HttpErrorResponse): Observable<any> => {
      return throwError(error);
    };
  }
}
