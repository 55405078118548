import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TokenService} from "../../../core/services";
import * as moment from "moment";

@Component({
  selector: 'app-offer-preview',
  templateUrl: './offer-preview.component.html',
  styleUrls: ['./offer-preview.component.scss'],
})
export class OfferPreviewComponent implements OnInit, OnChanges {
  @Input() formData: any
  @Input() isCompetition: boolean = false
  @Input() imageArray = []
  @Input() startDate : string ;
  userplaceholderImg = "assets/image/user-dummy.jpg"
  @Output() showPreview: EventEmitter<boolean> = new EventEmitter<boolean>();
  fbPage: string = ''
  @Input() offerText : string = ''
  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['imageArray']) {
      this.imageArray = changes['imageArray'].currentValue
    }
  }

  ngOnInit() {
    this.formData = {
      ...this.formData,
      offerText: this.formData?.offerText?.replace(/\n/g, '<br/>')
    };
    TokenService.getUserDetail().then((res) => {
      this.fbPage = res['facebook']['page_name']
    })
  }

  closePreview() {
    this.showPreview.emit(false)
  }

  formatDate(date: string) {
    return moment(date, 'YYYY-MM-DD HH:mm').format('MMMM D')
  }

}
