import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})

export class LinkService {
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  addPreconnectLink(url: string) {
    const linkElement = this.renderer.createElement('link');
    this.renderer.setAttribute(linkElement, 'rel', 'preconnect');
    this.renderer.setAttribute(linkElement, 'href', url);
    this.renderer.setAttribute(linkElement, 'crossorigin', 'true');
    this.renderer.appendChild(document.head, linkElement);

    console.log(document)
  }
}
