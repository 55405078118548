import {Injectable} from '@angular/core';
import {ApiService, TokenService} from '../../../core/services';
import {catchError} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";

@Injectable({providedIn: 'root'})

export class CitycalendarServices {
  constructor(
    private _apiService: ApiService
  ) {
  }

  createGiftBox(params: any) {
    const url = '/gift-box'
    return this._apiService.post(url, params)
  }

  updateGiftBox(id: any , params: any) {
    const url = `/gift-box/${id}`
    return this._apiService.put(url,params)
  }

  repostGiftBox(id, params) {
    let url = `/gift-box/${id}/repost`
    return this._apiService.post(url, params)
  }

  getGiftBoxById(id: any) {
    const url = `/gift-box/${id}`
    return this._apiService.get(url)
  }

  deleteGiftBoxById(id: any) {
    const url = `/gift-box/${id}`
    return this._apiService.del(url)
  }

  getGiftBoxList(tab: any, page: number) {
    let url: string = `/gift-box?status=${tab}&page=${page}`
    return this._apiService.get(url)
  }

  getOverView(id: number) {
    let url = `/gift-box/${id}/overview`
    return this._apiService.get(url)
  }

  checkCityPostStatus() {
    let url = '/gift-box/check-status'
    return this._apiService.get(url)
  }

  printDocument(id:number): Observable<any> {
    const url = `/gift-box/${id}/overview/print`;
    return this._apiService.getJatak(url, { responseType: 'blob' }).pipe(
        catchError(this._handlerError()));
  }
  private _handlerError() {
    return (error: HttpErrorResponse): Observable<any> => {
      return throwError(error);
    };
  }
}
