import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-order-list',
  templateUrl: './no-order-list.component.html',
  styleUrls: ['./no-order-list.component.scss'],
})
export class NoOrderListComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
