import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-winners',
  templateUrl: './no-winners.component.html',
  styleUrls: ['./no-winners.component.scss'],
})
export class NoWinnersComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
