// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-winner {
  border: 2px dashed #C5C5C7;
  padding: 24px 0;
  border-radius: 8px;
}
.no-winner p {
  font-size: 12px;
  font-weight: 700;
  line-height: 16.74px;
  letter-spacing: 0.12px;
}
.no-winner no-winner-img {
  margin-top: 11px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/no-order-list/no-order-list.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,eAAA;EACA,kBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,sBAAA;AACJ;AAEE;EACE,gBAAA;AAAJ","sourcesContent":[".no-winner{\n  border: 2px dashed #C5C5C7;\n  padding: 24px 0;\n  border-radius: 8px;\n\n  p{\n    font-size: 12px;\n    font-weight: 700;\n    line-height : 16.74px;\n    letter-spacing: 0.12px;\n  }\n\n  no-winner-img{\n    margin-top: 11px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
