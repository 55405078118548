// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-container {
  width: 755px;
  margin: auto;
  padding: 20px 8px;
}

.content-title {
  width: 130px;
  padding: 2px;
}

.content-text {
  margin-top: 16px;
  padding: 2px;
}

.content-text-two {
  width: 120px;
  padding: 2px;
}

.content-text-show {
  width: 0;
}

.content-text-three {
  margin-top: 16px;
  width: 408px;
  padding: 2px;
}

.content-subtitle {
  margin-top: 24px;
  width: 145px;
  padding: 2px;
}

.content-box {
  padding: 30px;
  border-radius: 8px;
}

@media screen and (max-width: 425px) {
  .content-text {
    width: 400px;
  }
  .content-text-two {
    width: 400px;
  }
  .content-text-show {
    width: 150px;
  }
  .content-box {
    width: 400px;
  }
  .content-text-three {
    width: 400px;
  }
}
@media screen and (max-width: 375px) {
  .content-text {
    width: 348px;
  }
  .content-text-two {
    width: 348px;
  }
  .content-box {
    width: 348px;
  }
  .content-text-three {
    width: 348px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/settings-skeleton/settings-skeleton.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,iBAAA;AACF;;AACA;EACE,YAAA;EACA,YAAA;AAEF;;AAAA;EACE,gBAAA;EACA,YAAA;AAGF;;AADA;EACE,YAAA;EACA,YAAA;AAIF;;AAFA;EACE,QAAA;AAKF;;AAHA;EACE,gBAAA;EACA,YAAA;EACA,YAAA;AAMF;;AAJA;EACE,gBAAA;EACA,YAAA;EACA,YAAA;AAOF;;AALA;EACE,aAAA;EACA,kBAAA;AAQF;;AALA;EACA;IACE,YAAA;EAQA;EANA;IACE,YAAA;EAQF;EANA;IACE,YAAA;EAQF;EANA;IACE,YAAA;EAQF;EANA;IACE,YAAA;EAQF;AACF;AAPA;EACA;IACE,YAAA;EASA;EAPA;IACE,YAAA;EASF;EAPA;IACE,YAAA;EASF;EAPA;IACE,YAAA;EASF;AACF","sourcesContent":[".content-container{\n  width: 755px;\n  margin:auto;\n  padding:20px 8px;\n}\n.content-title{\n  width: 130px;\n  padding: 2px;\n}\n.content-text{\n  margin-top: 16px;\n  padding: 2px;\n }\n.content-text-two{\n  width: 120px;\n  padding: 2px;\n}\n.content-text-show{\n  width: 0;\n}\n.content-text-three{\n  margin-top: 16px;\n  width: 408px;\n  padding: 2px;\n}\n.content-subtitle{\n  margin-top: 24px;\n  width: 145px;\n  padding: 2px;\n}\n.content-box{\n  padding: 30px ;\n  border-radius: 8px;\n}\n\n@media screen and (max-width: 425px){\n.content-text{\n  width: 400px;\n}\n  .content-text-two{\n    width: 400px;\n  }\n  .content-text-show{\n    width: 150px;\n  }\n  .content-box{\n    width: 400px;\n  }\n  .content-text-three{\n    width: 400px;\n}}\n@media screen and (max-width: 375px) {\n.content-text{\n  width: 348px;\n}\n  .content-text-two{\n    width: 348px;\n  }\n  .content-box{\n    width: 348px;\n  }\n  .content-text-three{\n    width: 348px;\n  }}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
