import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-list',
  templateUrl: './no-list.component.html',
  styleUrls: ['./no-list.component.scss'],
})
export class NoListComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
